import { CampaignWizardStepKeys } from 'app/react-ui/features/Campaigns/CampaignWizardV3/CampaignWizardV3.types';

/* eslint-disable import/export */
export const ACCOUNT_SETUP_ROUTE = '/setup-account';
export const CLIENT_ACCOUNT_SETUP_ROUTE = '/:clientName/setup-account';
export const ADMIN_ROUTE = '/admin';
export const ADMIN_HOME_PATH = '/koddi-tools';
export const MEDIA_PLANS_ROUTE = '/plans';

export const LANDING_ROUTE = `/clients/:clientId/`;

export const DASHBOARD_ROUTE = '/clients/:clientId/dashboard';

// Publisher Insights
export const PUBLISHER_DASHBOARD_ROUTE =
    '/clients/:clientId/dashboard/publisher';

export const OBSERVABILITY_DASHBOARD_ROUTE = `/clients/:clientId/ad-monitoring`;

export const INTERNAL_REPORTS_ROUTE = '/clients/:clientId/internal-reports';

export function pathToInternalReports(clientId) {
    return INTERNAL_REPORTS_ROUTE.replace(':clientId', clientId);
}

export function pathToObservabilityDashboard(clientId) {
    return OBSERVABILITY_DASHBOARD_ROUTE.replace(':clientId', clientId);
}

export function pathToPublisherDashboard(clientId) {
    return PUBLISHER_DASHBOARD_ROUTE.replace(':clientId', clientId);
}

export const PUBLISHER_INVENTORY_ROUTE = `${PUBLISHER_DASHBOARD_ROUTE}/inventory`;
export function pathToPublisherInventory(clientId) {
    return PUBLISHER_INVENTORY_ROUTE.replace(':clientId', clientId);
}

export const PUBLISHER_ADVERTISER_ROUTE = `${PUBLISHER_DASHBOARD_ROUTE}/advertiser`;
export function pathToPublisherAdvertiser(clientId) {
    return PUBLISHER_ADVERTISER_ROUTE.replace(':clientId', clientId);
}

export const PUBLISHER_OTHER_ROUTE = `${PUBLISHER_DASHBOARD_ROUTE}/other`;
export function pathToPublisherOther(clientId) {
    return PUBLISHER_OTHER_ROUTE.replace(':clientId', clientId);
}

// Media Review
export const MEDIA_REVIEW_ROUTE = '/clients/:clientId/campaigns/review-media';

export function pathToMediaReview(clientId) {
    return MEDIA_REVIEW_ROUTE.replace(':clientId', clientId);
}

// Recommendations Dashboard
export const RECOMMENDATIONS_DASHBOARD_ROUTE =
    '/clients/:clientId/advertisers/:advertiserId/recommendations';

export function pathToRecommendationsDashboad(clientId, advertiserId) {
    return RECOMMENDATIONS_DASHBOARD_ROUTE.replace(
        ':clientId',
        clientId
    ).replace(':advertiserId', advertiserId);
}

// Campaigns
export const CAMPAIGNS_ROUTE =
    '/clients/:clientId/advertisers/:advertiserId/campaigns';

export function pathToCampaignOverview(clientId, advertiserId) {
    return CAMPAIGNS_ROUTE.replace(':clientId', clientId).replace(
        ':advertiserId',
        advertiserId
    );
}

export const AD_GROUPS_ROUTE =
    '/clients/:clientId/advertisers/:advertiserId/campaigns/ad_groups';

export function pathToAdGroupOverview(clientId, advertiserId) {
    return AD_GROUPS_ROUTE.replace(':clientId', clientId).replace(
        ':advertiserId',
        advertiserId
    );
}

export const ENTITIES_ROUTE =
    '/clients/:clientId/advertisers/:advertiserId/campaigns/:entityLabel';

export function pathToEntityOverview(clientId, advertiserId, entityLabel) {
    return ENTITIES_ROUTE.replace(':clientId', clientId)
        .replace(':advertiserId', advertiserId)
        .replace(':entityLabel', entityLabel.plural.toLowerCase());
}
export const OLD_REPORTING_ROUTE =
    '/clients/:clientId/advertisers/:advertiserId/reporting';

export const REPORTING_ROUTE = '/clients/:clientId/reporting';

export function pathToReportingOverview(clientId) {
    return REPORTING_ROUTE.replace(':clientId', clientId);
}

export const OLD_SAVED_REPORTS_ROUTE = `${OLD_REPORTING_ROUTE}/saved_reports`;
export const SAVED_REPORTS_ROUTE = `${REPORTING_ROUTE}/saved_reports`;

export function pathToSavedReportsOverview(clientId) {
    return SAVED_REPORTS_ROUTE.replace(':clientId', clientId);
}

export const INDIVIDUAL_SAVED_REPORT_ROUTE = `${SAVED_REPORTS_ROUTE}/:reportId`;

export function pathToSavedReport(clientId, reportId) {
    return INDIVIDUAL_SAVED_REPORT_ROUTE.replace(':clientId', clientId).replace(
        ':reportId',
        reportId
    );
}
export const SCHEDULED_REPORTS_ROUTE =
    '/clients/:clientId/reporting/saved_report/:savedReportId/schedule';
export function pathToScheduledReport(clientId, savedReportId, isEmbedded) {
    return `${
        isEmbedded ? '/embedded' : ''
    }/clients/${clientId}/reporting/saved_report/${savedReportId}/schedule`;
}

export const CREATE_CAMPAIGNS_ROUTE = `/clients/:clientId/advertisers/:advertiserId/campaigns/create`;
export const CREATE_CAMPAIGNS_WITH_EXPERIENCE_ROUTE = `/clients/:clientId/advertisers/:advertiserId/experiences/:experienceId/campaigns/create`;

export const EDIT_CAMPAIGN_ROUTE = `/clients/:clientId/advertisers/:advertiserId/campaigns/:campaignId`;
export const VIEW_AD_GROUPS_ROUTE = `${EDIT_CAMPAIGN_ROUTE}/ad_groups`;
export const CREATE_AD_GROUP_ROUTE = `${EDIT_CAMPAIGN_ROUTE}/ad_groups/create`;
export const EDIT_AD_GROUP_ROUTE = `${EDIT_CAMPAIGN_ROUTE}/ad_groups/:adGroupId`;
export const LOGIN_ROUTE = '/login';
export const CLIENT_LOGIN_ROUTE = '/:clientName/login';
export const CREATE_CAMPAIGNS_ROUTE_V2 = `/clients/:clientId/advertisers/:advertiserId/campaigns/create/v2`;

export const EDIT_CAMPAIGN_ROUTE_V2 = `/clients/:clientId/advertisers/:advertiserId/campaigns/:campaignId/v2`;

export const CLIENT_ADVERTISER_MANAGEMENT_ROUTE = `${ADMIN_ROUTE}/clients`;
export const CLIENT_ADVERTISER_MANAGEMENT_CLIENTS_ROUTE = `${ADMIN_ROUTE}/clients`;
export const CLIENT_ADVERTISER_MANAGEMENT_ADVERTISER_GROUPS_ROUTE = `${CLIENT_ADVERTISER_MANAGEMENT_ROUTE}/advertiser-groups`;
export const CLIENT_ADVERTISER_MANAGEMENT_ADVERTISERS_ROUTE = `${CLIENT_ADVERTISER_MANAGEMENT_ROUTE}/advertisers`;
export const USER_MANAGEMENT_ROUTE = `${ADMIN_ROUTE}/users`;

export function pathToCreateCampaign(clientId, advertiserId, experienceId) {
    if (experienceId) {
        return `/clients/${clientId}/advertisers/${advertiserId}/experiences/${experienceId}/campaigns/create`;
    }
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/create`;
}

export function pathToTemplateOverview(clientId) {
    return `/admin/clients/${clientId}/template-overview`;
}

export function pathToEditCampaign(clientId, advertiserId, campaignId) {
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/${campaignId}`;
}

export function pathToCreateCampaignV2(clientId, advertiserId) {
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/create/v2`;
}

export function pathToEditCampaignV2(clientId, advertiserId, campaignId) {
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/${campaignId}/v2`;
}

export function pathToCampaignAdGroupV2(clientId, advertiserId, campaignId) {
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/${campaignId}/v2?step=${CampaignWizardStepKeys.CustomAdGroups}`;
}
export function pathToEditAdGroup(
    clientId,
    advertiserId,
    campaignId,
    adGroupId
) {
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/${campaignId}/ad_groups/${adGroupId}`;
}

export function pathToCreateAdGroup(clientId, advertiserId, campaignId) {
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/${campaignId}/ad_groups/create`;
}

export function pathToViewAdGroups(clientId, advertiserId, campaignId) {
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/${campaignId}/ad_groups`;
}

// new
export const CLIENT_CREATE_PATH = '/koddi-tools/clients/create';
export const ADVERTISER_GROUP_CREATE = '/koddi-tools/advertiser-groups/create';
export const ADVERTISER_CREATE_PATH = '/koddi-tools/advertisers/create';
export const USER_CREATE_PATH = '/koddi-tools/users/create';

export const USER_PROFILE_PATH = '/admin/users/:userId';

export const ADMIN_CLIENT_MANAGEMENT = '/admin/clients/:clientId';

export const ADMIN_ADVERTISER_MANAGEMENT =
    '/admin/clients/:clientId/advertisers/:advertiserId';

export const ADD_ADVERTISER_SPEND_LIMITS =
    '/admin/clients/:clientId/spend-limits/add';
export const EDIT_CLIENT_SPEND_LIMIT =
    '/admin/clients/:clientId/spend-limits/:spendLimitId/edit';
export const CLIENT_SETTINGS = '/admin/clients/:clientId/settings';
export const CLIENT_TARGETING_CONFIGURATION_EDIT =
    '/admin/clients/:clientId/targeting-configuration/:targetingId/edit';
export const CLIENT_TARGETING_CONFIGURATION_EDIT_V2 =
    '/admin/clients/:clientId/experiences/:experienceId/targeting-configuration/:targetingId/edit';
export const CLIENT_TARGETING_CONFIGURATION_CREATE =
    '/admin/clients/:clientId/create-targeting-configuration';
export const CLIENT_TEMPLATE_CREATION =
    '/admin/clients/:clientId/template/create';
export const CLIENT_TEMPLATE_EDIT = `/admin/clients/:clientId/template/:templateId`;

export const CLIENT_ADD_CURRENCY = '/admin/clients/:clientId/add-currency';

export const ADVERTISER_GROUP_PROFILE_PATH =
    '/admin/clients/:clientId/advertiser-groups/:advertiserGroupId/:resourceType';

export const ADVERTISER_USERS =
    '/admin/clients/:clientId/advertisers/:advertiserId/users';
export const ADVERTISER_ENTITIES =
    '/admin/clients/:clientId/advertisers/:advertiserId/entities';
export const ADVERTISER_PAYMENT =
    '/admin/clients/:clientId/advertisers/:advertiserId/payment';
export const ADVERTISER_TRANSACTIONS =
    '/admin/clients/:clientId/advertisers/:advertiserId/transaction-history';

export function pathToEditAdvertiserDetails(clientId, advertiserId) {
    return `/admin/clients/${clientId}/advertisers/${advertiserId}/users?edit=true`;
}

export function pathToClientConfiguration(clientId) {
    return `/admin/clients/${clientId}/client-configuration-tool`;
}

export function pathToAddFunds(clientId, advertiserId) {
    return `/admin/clients/${clientId}/advertisers/${advertiserId}?tab=payment`;
}

export function pathToAddAdvertiserSpendLimits(clientId) {
    return `/admin/clients/${clientId}/spend-limits/add`;
}

export function pathToEditClientSpendLimit(clientId, spendLimitId) {
    return `/admin/clients/${clientId}/spend-limits/${spendLimitId}/edit`;
}

export function pathToAdminSettings(clientId) {
    return `/admin/clients/${clientId}/settings`;
}

// ----- current routes ------
export const CONTEXTUAL_ADVERTISER_GROUP_CREATE_PATH =
    '/admin/clients/:clientId/add-advertiser-groups';
export const CLIENT_CONTEXTUAL_USER_CREATE =
    '/admin/clients/:clientId/add-users';
export const ADVERTISER_CONTEXTUAL_USER_CREATE =
    '/admin/clients/:clientId/advertisers/:advertiserId/add-users';
export const CONTEXTUAL_ADVERTISER_CREATE =
    '/admin/clients/:clientId/add-advertisers';

export const SETTINGS_PATH = '/admin/clients/:clientId/settings';

export const CONTEXTUAL_CLIENT_USER_PROFILE =
    '/admin/clients/:clientId/users/:userId';

export const CONTEXTUAL_CLIENT_USER_EDIT =
    '/admin/clients/:clientId/users/:userId/edit';

export const CONTEXTUAL_ADVERTISER_USER_PROFILE =
    '/admin/clients/:clientId/advertisers/:advertiserId/users/:userId';

export const CONTEXTUAL_ADVERTISER_USER_EDIT =
    '/admin/clients/:clientId/advertisers/:advertiserId/users/:userId/edit';

/** Registration path routes */
export const REGISTRATION_ROUTE = '/:clientName/register';
export const ADVERTISER_REGISTER_BILLING_ROUTE =
    '/:clientName/register/advertisers/billing';
export const ADVERTISER_REGISTER_ADD_ADVERTISER =
    '/:clientName/register/advertisers/billing/addAdvertiser';
export const ADVERTISER_REGISTER_PAYMENT_ROUTE =
    '/:clientName/register/advertisers/:advertiserId/payment';
export const ADVERTISER_REGISTER_ENTITIES_ROUTE =
    '/:clientName/register/advertisers/:advertiserId/entities';
export const ADVERTISER_REGISTER_ROUTE = '/:clientName/register/advertisers/*';
export const pathToAdvertiserRegistration = (
    clientName,
    pathname,
    advertiserId
) => {
    if (advertiserId) {
        return `${ADVERTISER_REGISTER_ROUTE.replace(
            '*',
            `${advertiserId}/`
        ).replace(':clientName', clientName)}${pathname || ''}`;
    }
    return `${ADVERTISER_REGISTER_ROUTE.replace('*', '').replace(
        ':clientName',
        clientName
    )}${pathname || ''}`;
};

/** Embedded Routes */
export const EMBEDDED_CHART_PATH = '/embedded/clients/:clientId/chart';
export const EMBEDDED_CHART_PATH_CAMPAIGNS =
    '/embedded/clients/:clientId/advertisers/:id/campaigns/:campaignId/chart';

export const EMBEDDED_CHART_PATH_MEDIA =
    '/embedded/clients/:clientId/advertisers/:id/campaigns/:campaignId/media-chart';

export const EMBEDDED_CHART_PATH_ADGROUPS =
    '/embedded/clients/:clientId/advertisers/:id/campaigns/:campaignId/ad_groups/:adGroupId/chart';
export const EMBEDDED_REPORTING_PATH = '/embedded/clients/:clientId/reporting';
export const EMBEDDED_SAVED_REPORTS_PATH =
    '/embedded/clients/:clientId/saved-reports';
export const EMBEDDED_SCHEDULED_REPORTS_PATH =
    '/embedded/clients/:clientId/reporting/saved_report/:savedReportId/schedule';
export const EMBEDDED_ERROR_PATH = '/embedded/error';

export const EMBEDDED_INDIVIDUAL_SAVED_REPORT_ROUTE = `${EMBEDDED_SAVED_REPORTS_PATH}/:reportId`;

export function pathToEmbeddedSavedReport(clientId, reportId) {
    return EMBEDDED_INDIVIDUAL_SAVED_REPORT_ROUTE.replace(
        ':clientId',
        clientId
    ).replace(':reportId', reportId);
}
export const EMBEDDED_INSERTION_ORDER_ROUTE =
    '/embedded/clients/:clientId/insertion-orders';

export function pathToEmbeddedInsertionOrder(clientId) {
    return `/embedded/clients/${clientId}/insertion-orders`;
}
export function pathToEmbeddedCreateInsertionOrder(clientId) {
    return `/embedded/clients/${clientId}/insertion-orders/create`;
}
export function pathToEmbeddedEditInsertionOrder(clientId, insertionOrderId) {
    return `/embedded/clients/${clientId}/insertion-orders/edit/${insertionOrderId}`;
}

/** Insertion Order Routes */
export const INSERTION_ORDER_ROUTE =
    '/admin/clients/:client_id/advertisers?tab=insertion-orders';
export const INSERTION_ORDER_CREATE_ROUTE =
    '/admin/clients/:clientId/insertion-orders/create';
export const INSERTION_ORDER_EDIT_ROUTE =
    '/admin/clients/:client_id/insertion-orders/edit/:insertion_order_id';

export function pathToInsertionOrder(clientId) {
    return INSERTION_ORDER_ROUTE.replace(':client_id', clientId);
}
export function pathToCreateInsertionOrder(clientId) {
    return INSERTION_ORDER_CREATE_ROUTE.replace(':clientId', clientId);
}
export function pathToEditInsertionOrder(clientId, insertionOrderId) {
    return INSERTION_ORDER_EDIT_ROUTE.replace(':client_id', clientId).replace(
        ':insertion_order_id',
        insertionOrderId
    );
}
