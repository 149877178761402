import React from 'react';
import { Box } from '@chakra-ui/react';
import { Shadows } from '../ChakraTheme';
import { Border, BorderWidth } from '../ChakraTheme/configs/Borders.config';
import { Colors } from '../ChakraTheme/configs/Colors.config';
import { Spacing } from '../ChakraTheme/configs/Spacing.config';
import { CRow } from '../Layouts';
import { CardBaseProps } from './Cards.type';

const CCardBase = ({ children, ...props }: CardBaseProps): JSX.Element => (
    <Box
        borderRadius={Border.Radius.SM}
        borderStyle="solid"
        p={Spacing.SM}
        wordBreak="break-word"
        {...props}
    >
        {children}
    </Box>
);

export const CCardHeader = ({
    children,
    ...props
}: CardBaseProps): JSX.Element => (
    <CRow
        alignItems="center"
        borderBottom={`${BorderWidth.Thin} solid`}
        borderBottomColor={Colors.grayLightest}
        pb={Spacing.SM}
        mb={Spacing.SM}
        {...props}
    >
        {children}
    </CRow>
);

export const CGrayCard = ({
    children,
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase {...props} bgColor={Colors.cardGray}>
        {children}
    </CCardBase>
);

export const CWhiteCard = ({
    children,
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase
        p={Spacing.MD}
        bgColor={Colors.white}
        borderColor={Colors.grayLight}
        borderWidth={BorderWidth.Thinnest}
        {...props}
    >
        {children}
    </CCardBase>
);

export const CKpiCard = ({
    children,
    color = 'red',
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase
        p={Spacing.MD}
        bgColor={Colors.white}
        borderColor={Colors.grayLight}
        borderWidth={BorderWidth.Thinnest}
        borderBottomColor={color}
        borderBottomWidth={5}
        borderRadius={Border.Radius.MD}
        {...props}
    >
        {children}
    </CCardBase>
);

export const CShadowedCard = ({
    children,
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase
        p={Spacing.MD}
        bgColor={Colors.white}
        borderColor="transparent"
        borderWidth={BorderWidth.Thinnest}
        boxShadow={Shadows.BASE}
        {...props}
    >
        {children}
    </CCardBase>
);

export const CBorderedCard = ({
    children,
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase
        {...props}
        border={Border.Width.Thin}
        borderColor={Colors.primary}
        bg={Colors.white}
    >
        {children}
    </CCardBase>
);

export const CBorderedGrayCard = ({
    children,
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase
        {...props}
        border={Border.Width.Thin}
        borderColor={Colors.grayLight}
        bgColor={Colors.grayLightest}
    >
        {children}
    </CCardBase>
);

// TODO:  come back to cards and convert border and white card to base variants

export const CBorderedSuccessCard = ({
    children,
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase
        {...props}
        p={Spacing.SM}
        border={Border.Width.Thin}
        borderColor={Colors.success}
    >
        {children}
    </CCardBase>
);

type SlotPosition = 'left' | 'right';
type Slots = Partial<Record<SlotPosition, JSX.Element>>;

const SlotStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: '200px',
};

export const CSlottedCard = ({
    slots,
    children,
    ...props
}: CardBaseProps & {
    slots: Slots;
}): JSX.Element => {
    const hasSlots = Object.keys(slots).length > 0;

    return (
        <CCardBase
            {...props}
            w="100%"
            p={0}
            border={BorderWidth.Thinnest}
            borderColor={Colors.grayLight}
            bg={Colors.white}
            display="flex"
            boxShadow={Shadows.SMALL}
            overflow="hidden"
        >
            {slots.left && (
                <Box {...SlotStyles} bg={Colors.grayLightest}>
                    {slots.left}
                </Box>
            )}

            <Box
                flexGrow={1}
                flexShrink={1}
                w="100%"
                h="100%"
                minWidth={0}
                pl={hasSlots ? Spacing.SM : 0}
            >
                {children}
            </Box>

            {slots.right && <Box {...SlotStyles}>{slots.right}</Box>}
        </CCardBase>
    );
};

export const CBottomAccentBorderedCard = ({
    children,
    borderBottomColor,
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase
        {...props}
        border={Border.Width.Thin}
        borderColor={Colors.grayLight}
        borderBottomColor={borderBottomColor}
        bgColor={Colors.white}
    >
        {children}
    </CCardBase>
);
