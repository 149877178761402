import { DatePickerPresetDateRange } from 'app/koddi-components/src/DateRangePicker/DateRangePicker.types';
import { KoddiAPIQuery } from 'app/react-ui/types';
import { ReportFilter, OrGroup, Sort, Order, Pagination } from '../../../types';

export type ReportType = 'total' | 'trend' | 'normal' | 'count';

export type ReportContext =
    | 'media_plan'
    | 'campaign'
    | 'ad_group'
    | 'entity'
    | 'ad_group_entity'
    | 'general'
    | 'publisher_ad_group_insights'
    | 'offsite'
    | 'publisher_inventory_insights';

export type ReportSlice =
    | ReportContext
    | 'saved'
    | 'review'
    | 'insights'
    | 'insights-inventory'
    | 'insights-advertisers'
    | 'simple'
    | 'offsite';

export type DimensionType = 'string' | 'number' | 'date';

export type Dimension =
    | 'ad_group_id'
    | 'ad_group_name'
    | 'ad_id'
    | 'ad_name'
    | 'advertiser_id'
    | 'advertiser_name'
    | 'campaign_id'
    | 'campaign_name'
    | 'campaign_template_id'
    | 'daily_date'
    | 'entity_bid'
    | 'entity_id'
    | 'entity_name'
    | 'line_item_id'
    | 'line_item_name'
    | 'media_plan_id'
    | 'media_plan_name'
    | 'media_strategy_id'
    | 'media_strategy_name'
    | 'publisher'
    | 'restaurant_id'
    | 'restaurant_name'
    | 'internal_entity_id'
    | 'experience_name'
    | 'experience_id'
    | 'review_status'
    | 'site_id'
    | 'placement'
    | 'slot'
    | 'target'
    | 'advertiser_type'
    | 'ad_group_start_date'
    | 'ad_group_end_date'
    | 'ad_group_status '
    | 'ad_group_budget'
    | 'ad_group_budget_type'
    | 'pacing'
    | 'campaign_status'
    | 'primaryAccountName';

export type CreateReportInput = {
    report_type: ReportType;
    context: ReportContext;
    filters?: Omit<ReportFilter, 'or_group'>[];
    or_group?: OrGroup;
    dimensions: Dimension[];
    metrics: string[];
    field?: string;
    sort?: Sort[];
    order?: Order;
    pagination?: Pagination;
    start?: number;
    start_date: string;
    end_date: string;
    member_group_id?: number | null;
    advertiser_ids?: number[] | null;
    all_advertisers_selected?: boolean;
    currency_code?: string;
};

export type ReportHeaderType =
    | 'string'
    | 'int'
    | 'currency'
    | 'decimal'
    | 'text'
    | 'number'
    | 'percentage'
    | '%d:1';

export type ReportHeader = {
    name: string;
    title: string;
    type: ReportHeaderType;
};

export type ReportResponse<ReportData extends Record<string, unknown>> = {
    headers: ReportHeader[];
    data: ReportData[];
};

export enum DimensionPropertyType {
    MediaStructure = 1,
    Targeting = 2,
    ProductDetails = 3,
}

export type DimensionConfig = {
    id: string;
    name: string;
    type: string;
    presentation: string;
    description: string;
    filterable: boolean;
    selectable: boolean;
    contexts: string[] | null;
    acceptedValues?: undefined | null;
    is_default?: boolean;
    property?: DimensionPropertyType;
    category?: string;
};

export type MetricConfig = {
    id: string;
    name: string;
    type: string;
    presentation: string;
    description: string;
    calculation: string;
    total: string;
    granularity: string[] | null;
    filterable: boolean;
    selectable: boolean;
    acceptedValues?: string[];
    contexts?: string[];
    value?: number;
    role_ids?: number[];
    category?: string;
    trendable?: boolean;
};

export type SettingsConfig = {
    acceptedValues: string[] | null;
    contexts: string[] | null;
    description: string;
    filterable: boolean;
    id: string;
    name: string;
    presentation: string;
    selectable: boolean;
    type: string;
    property: 0;
    role_ids?: number[];
    category?: string;
};

export type AnyFieldConfig = SettingsConfig | MetricConfig | DimensionConfig;

export type NumericTypes = 'int' | 'currency' | 'decimal';

export type FieldOrderConfig = {
    field_id: string;
    is_selected_by_default: boolean;
    order: number;
};

export type FormattedOperations = {
    [key: string]: string[];
};

export type CompetitiveInsightField = string;

export type Operation = {
    acceptedFieldTypes: string[];
    operation: string;
    name: string;
    description: string;
    displayAs?: string;
};

export interface ReportConfig {
    competitive_insight_fields: CompetitiveInsightField[] | null;
    reportTypes: ReportType[];
    contexts: string[];
    dimensions: {
        [key: string]: DimensionConfig;
    };
    default_graph_metric: string;
    operations: Operation[];
    numericTypes: NumericTypes[];
    metrics: {
        [key: string]: MetricConfig;
    };
    settings: {
        [key: string]: SettingsConfig;
    };
    formattedOperations: FormattedOperations;
    field_order: {
        [key: string]: FieldOrderConfig[];
    };
}

export type SavedReport = {
    created_at: string;
    last_edited_at: string;
    name: string;
    user: number;
    client_id: number;
    report_payload: string;
    lookback_window: DatePickerPresetDateRange;
    description?: string;
    selected_dimensions?: string[];
};

export type SavedReportData = {
    name?: string;
    report_payload?: CreateReportInput;
    // @TODO Fix Depencancy cycle and update below type to DatePickerPresetDateRange | null
    lookback_window?: string | null;
    selected_dimensions?: string[];
    description?: string;
};

export type SavedReports = Array<{
    created_at: string;
    description: string;
    last_edited_at: string;
    name: string;
    saved_report_id: number;
    has_report_schedules: boolean;
}>;

export type GetSavedReport = {
    name: string;
    user_name: string;
    link: string;
    saved_report_id: number;
    client_id: number;
    last_edited_at: string;
    created_at: string;
    description: string;
    has_report_schedules: boolean;
};

export type ReportSchedule = {
    report_schedule_id: number;
    name: string;
    deleted: boolean;
    last_edited_at: string;
    created_at: string;
    emails: string[];
    frequency: ReportScheduleFrequency;
    next_run: string;
    time_of_day?: string;
    day_of_week?: string;
    day_of_month?: string;
};

export type ReportSchedules = {
    report_schedules: ReportSchedule[];
};

export enum ReportScheduleFrequency {
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    OneTime = 'One Time',
}

export type ReportSchedulePayload = {
    name: string;
    emails: string[];
    next_run: string;
    frequency: ReportScheduleFrequency;
    time_of_day?: string;
    day_of_week?: string;
    day_of_month?: string;
};

export type UpdateReportSchedulePayload = ReportSchedulePayload & {
    deleted: boolean;
};

export enum StatusId {
    Open = 1,
    Pending = 2,
    Approved = 3,
    Ended = 4,
    Closed = 5,
}

export enum Status {
    Open = 'Open',
    Pending = 'Pending',
    Approved = 'Approved',
    Ended = 'Ended',
    Closed = 'Closed',
}

export type InsertionOrders = {
    insertion_order_id: number;
    member_group_id: number;
    name: string;
    insertion_order_number: string;
    status_id: StatusId;
    currency_id: number;
    total_amount: number;
    remaining_amount: number;
    start_date: string;
    end_date: string | null;
    last_edited_by: number;
    line_items: LineItem[];
};

export type InsertionOrder = {
    insertion_order_id: number;
    insertion_order_number: string;
    name: string;
    number_name: string;
    status: Status;
    remaining_amount: number;
};

export type InsertionOrderReport = {
    memberGroupId: number | null;
    advertiserId: number | null;
    params: KoddiAPIQuery & {
        filters?: ReportFilter[];
    };
    excludePagination?: boolean;
};

export type AdminInsertionOrderReport = Omit<
    InsertionOrderReport,
    'advertiserId'
>;

export type AdminInsertionOrderReportResponse = {
    status: string;
    insertion_order_id: number;
    name: string;
    line_item_count: number;
    campaign_count: number;
    total_amount: number;
    remaining_amount: number;
    start_date: string;
    end_date: string;
    always_on: boolean;
};

export type LineItemsReport = {
    memberGroupId: number | null;
    advertiserId: number | null;
    experienceId: number;
    insertionOrderId?: number;
    params: KoddiAPIQuery & {
        filters?: ReportFilter[];
    };
    excludePagination?: boolean;
};

export type LineItem = {
    line_item_id: number;
    local_id: number;
    name: string;
    line_item_number: string;
    number_name: string;
};

export enum CompetitiveInsightsStatusId {
    Competitive = 1,
    NotCompetitive = 2,
    NoData = 3,
    Disabled = 4,
}
export type CompetitiveInsights = {
    field_id: string;
    entity_value: number;
    leader_value: number;
    status_id: CompetitiveInsightsStatusId;
    status_name: string;
    entity_capped_value: number | null;
    leader_capped_value: number | null;
};

export type ReportStatus = {
    pending_report_id: string;
    status: string;
    filepath: string;
    error: string;
    created_at: string;
    finished_at: string;
};
