import React, { useMemo, useState } from 'react';
import { Field, FieldInputProps } from 'react-final-form';
import Label from 'koddi-components/Label';
import OnOutsideClick from 'koddi-components/OnOutsideClick';
import { Portal } from 'react-portal';
import { useKoddiTheme } from 'koddi-components/ThemeProvider';
import { format } from 'date-fns-tz';
import { subDays } from 'date-fns';
import {
    DateRangePickerProps,
    DateRangePickerFieldProps,
    DateRangePickerFieldComponentProps,
    DateRangePickerCompareType,
} from './DateRangePicker.types';

import DateRangePickerList from './DateRangePickerList';
import DateRangeButton from './DateRangeButton';
import DateRangeCalendars from './DateRangeCalendars';
import {
    DateRangePickerHookReturn,
    useDateRangePicker,
} from './DateRangePicker.hooks';
import DateRangePickerFooter from './DateRangePickerFooter';

import * as Styled from './DateRangePicker.styled';

import {
    CButtonGroup,
    CChevronButton,
    Colors,
    COutlineButton,
    CRow,
    CSecondaryButton,
    CSelectField,
    CTextInput,
    Spacing,
    Typography,
} from '../_ChakraComponents';
import { useChakraTheme } from '../_ChakraComponents/ChakraTheme/useChakraTheme.hooks';

const DateContent = ({
    dropdownOpen,
    selectedPresetDateRange,
    presetDateRanges,
    handlePresetRangeSelected,
    datesMap,
    months,
    hoveredDate,
    setHoveredDate,
    handleDatesRangeChange,
    rangeIsValid,
    disallowFutureDates,
    handleFirstMonthChange,
    handleSecondMonthChange,
    handleApply,
    handleCancel,
    error,
    name,
    inputProps,
    startDateId,
    endDateId,
    v2,
}: Pick<
    DateRangePickerHookReturn,
    | 'dropdownOpen'
    | 'selectedPresetDateRange'
    | 'presetDateRanges'
    | 'handlePresetRangeSelected'
    | 'datesMap'
    | 'months'
    | 'hoveredDate'
    | 'setHoveredDate'
    | 'handleDatesRangeChange'
    | 'rangeIsValid'
    | 'handleFirstMonthChange'
    | 'handleSecondMonthChange'
    | 'handleApply'
    | 'error'
> & {
    v2?: boolean;
    name: string;
    disallowFutureDates: boolean;
    inputProps: FieldInputProps<string, HTMLInputElement>['input'];
    startDateId?: string;
    endDateId?: string;
    handleCancel?: () => void;
}) => {
    return (
        <Styled.DateRangePickerDropdown
            role="dialog"
            dropdownOpen={dropdownOpen}
        >
            <DateRangePickerList
                selectedRange={selectedPresetDateRange}
                ranges={presetDateRanges}
                onRangeSelected={handlePresetRangeSelected}
            />
            <Styled.DateRangePickerContent>
                <DateRangeCalendars
                    {...datesMap}
                    {...months}
                    startDateId={startDateId || `${name}-start-date`}
                    endDateId={endDateId || `${name}-end-date`}
                    onDayHover={setHoveredDate}
                    hoveredDate={hoveredDate}
                    onFirstMonthDateChange={handleFirstMonthChange}
                    onSecondMonthDateChange={handleSecondMonthChange}
                    onChange={handleDatesRangeChange}
                    rangeIsValid={rangeIsValid}
                    disallowFutureDates={disallowFutureDates}
                    v2={v2}
                />
                <DateRangePickerFooter
                    onApply={handleApply}
                    onCancel={handleCancel}
                    error={error}
                    inputName={name}
                />
            </Styled.DateRangePickerContent>
            <Styled.DateRangePickerInput {...inputProps} readOnly />
        </Styled.DateRangePickerDropdown>
    );
};

/** The `Koddi` Date Range Picker */
export const DateRangePicker = ({
    startDate = new Date(),
    endDate = null,
    initialDateRangePreset,
    startDateId,
    endDateId,
    onDatesChange,
    allTimeDate,
    name,
    btnStyle,
    label,
    input = {
        name,
        value: '',
        onBlur: () => undefined,
        onChange: () => undefined,
        onFocus: () => undefined,
    },
    onApply = () => undefined,
    open = false,
    disallowFutureDates = false,
    v2,
    btnHeight,
    locale = 'en-US',
    disabled = false,
    chakraButton,
}: DateRangePickerProps): JSX.Element => {
    const { onChange, ...inputProps } = input;
    const { zIndex } = useKoddiTheme();

    const {
        rootRef,
        setPopperRef,
        setRootRef,
        styles,
        attributes,
        dropdownOpen,
        selectedPresetDateRange,
        datesMap,
        handleCancel,
        toggleDropdown,
        ...rest
    } = useDateRangePicker({
        startDate,
        endDate,
        initialDateRangePreset,
        onDatesChange,
        allTimeDate,
        locale,
        onApply,
        open,
        onChange,
    });

    return (
        <Styled.DateRangePickerWrapper
            marginRight={chakraButton ? 0 : 8}
            ref={setRootRef}
        >
            {label && <Label htmlFor={input.name}>{label}</Label>}
            <DateRangeButton
                btnStyle={btnStyle}
                height={btnHeight}
                presetDateRange={selectedPresetDateRange}
                startDate={datesMap.startDate}
                endDate={datesMap.endDate}
                toggleDropdown={toggleDropdown}
                dropdownOpen={dropdownOpen}
                disabled={disabled}
                chakraButton={chakraButton}
            />
            <Portal>
                <OnOutsideClick
                    listenForOutsideClick={dropdownOpen}
                    onOutsideClick={() => handleCancel()}
                    getRef={setPopperRef}
                    style={{
                        ...styles.popper,
                        zIndex: zIndex.dropdown,
                    }}
                    additionalElement={rootRef}
                    {...attributes.popper}
                >
                    <DateContent
                        {...rest}
                        dropdownOpen={dropdownOpen}
                        selectedPresetDateRange={selectedPresetDateRange}
                        datesMap={datesMap}
                        handleCancel={handleCancel}
                        inputProps={inputProps}
                        name={name}
                        disallowFutureDates={disallowFutureDates}
                        startDateId={startDateId}
                        endDateId={endDateId}
                        v2={v2}
                    />
                </OnOutsideClick>
            </Portal>
        </Styled.DateRangePickerWrapper>
    );
};

export const SplitDateRangePicker = ({
    startDate = new Date(),
    endDate = null,
    initialDateRangePreset,
    startDateId,
    endDateId,
    onDatesChange,
    allTimeDate,
    name,
    input = {
        name,
        value: '',
        onBlur: () => undefined,
        onChange: () => undefined,
        onFocus: () => undefined,
    },
    onApply = () => undefined,
    open = false,
    disallowFutureDates = false,
    v2,
    btnHeight,
    locale = 'en-US',
    disabled = false,
    chakraButton,
}: DateRangePickerProps): JSX.Element => {
    const { onChange, ...inputProps } = input;
    const { zIndex } = useKoddiTheme();

    const {
        rootRef,
        setPopperRef,
        setRootRef,
        styles,
        attributes,
        dropdownOpen,
        selectedPresetDateRange,
        datesMap,
        handleCancel,
        toggleDropdown,
        dates,
        ...rest
    } = useDateRangePicker({
        startDate,
        endDate,
        initialDateRangePreset,
        onDatesChange,
        allTimeDate,
        locale,
        onApply,
        open,
        onChange,
    });

    return (
        <Styled.DateRangePickerWrapper
            marginRight={chakraButton ? 0 : 8}
            ref={setRootRef}
        >
            <CButtonGroup isAttached>
                <COutlineButton
                    testId="split-date-range-button"
                    height="30px"
                    maxWidth="220px"
                    minWidth="220px"
                    bg={Colors.white}
                    color={Colors.black}
                    cursor="default"
                    fontWeight={Typography.fontWeights.semibold}
                >
                    {`${format(
                        new Date(`${dates.startDate}`) ?? new Date(),
                        'LLL dd, yyyy'
                    )} - ${format(
                        dates.endDate ?? new Date(),
                        'LLL dd, yyyy'
                    )}`}
                </COutlineButton>
                <DateRangeButton
                    btnStyle="secondary"
                    height={btnHeight}
                    presetDateRange={selectedPresetDateRange}
                    startDate={datesMap.startDate}
                    endDate={datesMap.endDate}
                    toggleDropdown={toggleDropdown}
                    dropdownOpen={dropdownOpen}
                    disabled={disabled}
                    chakraButton={chakraButton}
                    maxWidth="135px"
                    minWidth="135px"
                />
            </CButtonGroup>
            <Portal>
                <OnOutsideClick
                    listenForOutsideClick={dropdownOpen}
                    onOutsideClick={() => handleCancel()}
                    getRef={setPopperRef}
                    style={{
                        ...styles.popper,
                        zIndex: zIndex.dropdown,
                    }}
                    additionalElement={rootRef}
                    {...attributes.popper}
                >
                    <DateContent
                        {...rest}
                        dropdownOpen={dropdownOpen}
                        selectedPresetDateRange={selectedPresetDateRange}
                        datesMap={datesMap}
                        handleCancel={handleCancel}
                        inputProps={inputProps}
                        name={name}
                        disallowFutureDates={disallowFutureDates}
                        startDateId={startDateId}
                        endDateId={endDateId}
                        v2={v2}
                    />
                </OnOutsideClick>
            </Portal>
        </Styled.DateRangePickerWrapper>
    );
};

export const MultiRangeDatePicker = ({
    initialDateRange,
    initialDateRangePreset,
    startDateId,
    endDateId,
    onDatesChange,
    allTimeDate,
    name,
    input = {
        name,
        value: '',
        onBlur: () => undefined,
        onChange: () => undefined,
        onFocus: () => undefined,
    },
    onApply = () => undefined,
    open = false,
    disallowFutureDates = false,
    locale = 'en-US',
    disabled = false,
    chakraButton,
    compareOpen = false,
    placement = 'bottom-start',
    allowPrevious = true,
    initialCompareType = 'week',
}: Omit<DateRangePickerProps, 'startDate' | 'endDate'> & {
    initialDateRange: {
        startDate: Date;
        endDate: Date;
        previousStartDate: Date | null;
        previousEndDate: Date | null;
    };
    initialCompareType?: DateRangePickerCompareType;
    allowPrevious?: boolean;
    compareOpen?: boolean;
}): JSX.Element => {
    const { onChange, ...inputProps } = input;
    const { zIndex } = useKoddiTheme();
    const [toggleCompare, setToggleCompare] = useState(compareOpen);
    const [initialized, setInitialized] = useState(false);
    const [isCurrent, setIsCurrent] = useState(true);
    const [dateRange, setDateRange] = useState<{
        startDate: Date | null;
        endDate: Date | null;
        previousStartDate: Date | null;
        previousEndDate: Date | null;
    }>(initialDateRange);
    const [selectedComparison, setSelectedComparison] = useState<
        DateRangePickerCompareType
    >(initialCompareType);
    const theme = useChakraTheme();

    const formatDateRange = (date1: Date | null, date2: Date | null) => {
        let str = '';
        if (date1) {
            str = date1 ? format(date1, 'MM/dd/yy') : '';
        }
        if (date2) {
            str = `${str} - ${date2 ? format(date2, 'MM/dd/yy') : ''}`;
        }
        return str;
    };

    const currentFormattedRange = useMemo(() => {
        return formatDateRange(dateRange.startDate, dateRange.endDate);
    }, [dateRange.endDate, dateRange.startDate]);
    const previousFormattedRange = useMemo(() => {
        return formatDateRange(
            dateRange.previousStartDate ??
                subDays(new Date(dateRange.startDate ?? new Date()), 7),
            dateRange.previousEndDate ??
                subDays(new Date(dateRange.endDate ?? new Date()), 7)
        );
    }, [
        dateRange.endDate,
        dateRange.previousEndDate,
        dateRange.previousStartDate,
        dateRange.startDate,
    ]);

    const onPreviousDatesChange = ({
        startDate,
        endDate,
    }: {
        startDate: Date | null;
        endDate: Date | null;
    }) => {
        setInitialized(true);
        setSelectedComparison('custom');
        setDateRange((prev) => ({
            ...prev,
            previousStartDate: startDate,
            previousEndDate: endDate,
        }));
    };
    const onCurrentDatesChange = ({
        startDate,
        endDate,
    }: {
        startDate: Date | null;
        endDate: Date | null;
    }) => {
        setDateRange((prev) => ({
            ...prev,
            startDate,
            endDate,
        }));
    };

    const currentDateRangePickerProps = useDateRangePicker({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        initialDateRangePreset: null,
        onDatesChange: onCurrentDatesChange,
        allTimeDate,
        locale,
        onApply,
        open,
        onChange,
        placement,
    });

    const previousDateRangePickerProps = useDateRangePicker({
        startDate: dateRange.previousStartDate,
        endDate: dateRange.previousEndDate,
        initialDateRangePreset: null,
        onDatesChange: onPreviousDatesChange,
        allTimeDate,
        locale,
        onApply,
        open,
        onChange,
        placement,
    });

    const applyDates = () => {
        onApply({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            previousStartDate: toggleCompare
                ? dateRange.previousStartDate
                : null,
            previousEndDate: toggleCompare ? dateRange.previousEndDate : null,
            buttonLabel: initialDateRangePreset,
            compareOpen: toggleCompare,
        });
        currentDateRangePickerProps.setDropdownOpen(false);
    };

    const calculateComparisonDates = (
        value: string | undefined,
        dates: { startDate: Date | null; endDate: Date | null }
    ) => {
        const daysMap = {
            day: 1,
            week: 7,
            month: 30,
            default: 7,
        };

        const days = daysMap[value as keyof typeof daysMap] || daysMap.default;

        return {
            previousStartDate: subDays(dates.startDate ?? new Date(), days),
            previousEndDate: subDays(dates.endDate ?? new Date(), days),
        };
    };

    const handleCompareInputChange = (value?: string) => {
        const newValue = value as DateRangePickerCompareType;
        setSelectedComparison(newValue);
        setDateRange((prev) => ({
            ...prev,
            ...calculateComparisonDates(value, prev),
        }));
    };

    const handleToggleCompare = () => {
        if (toggleCompare) {
            setIsCurrent(true);
            setDateRange((prev) => ({
                ...prev,
                previousStartDate: null,
                previousEndDate: null,
            }));
        }
        const { previousStartDate, previousEndDate } = calculateComparisonDates(
            selectedComparison,
            {
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
            }
        );
        setDateRange((prev) => ({
            ...prev,
            previousStartDate: initialized
                ? dateRange.previousStartDate
                : previousStartDate,
            previousEndDate: initialized
                ? dateRange.previousEndDate
                : previousEndDate,
        }));
        setToggleCompare(!toggleCompare);
    };

    return (
        <Styled.DateRangePickerWrapper
            marginRight={chakraButton ? 0 : 8}
            ref={currentDateRangePickerProps.setRootRef}
        >
            <CChevronButton
                chevronDirection={
                    currentDateRangePickerProps.dropdownOpen ? 'up' : 'down'
                }
                onClick={currentDateRangePickerProps.toggleDropdown}
                testId="multi-date-range-dropdown-button"
                disabled={disabled}
            >
                {`${currentFormattedRange}
                    ${
                        previousFormattedRange.length > 0 && toggleCompare
                            ? `   &    ${previousFormattedRange}`
                            : ''
                    }`}
            </CChevronButton>
            <Portal>
                <OnOutsideClick
                    listenForOutsideClick={
                        currentDateRangePickerProps.dropdownOpen
                    }
                    onOutsideClick={() => {
                        setToggleCompare(compareOpen);
                        currentDateRangePickerProps.setDropdownOpen(false);
                        onDatesChange?.({
                            startDate:
                                currentDateRangePickerProps.restoreDates
                                    .startDate,
                            endDate:
                                currentDateRangePickerProps.restoreDates
                                    .endDate,
                            previousStartDate:
                                previousDateRangePickerProps.restoreDates
                                    .startDate,
                            previousEndDate:
                                previousDateRangePickerProps.restoreDates
                                    .endDate,
                            buttonLabel: initialDateRangePreset,
                        });
                    }}
                    getRef={currentDateRangePickerProps.setPopperRef}
                    style={{
                        ...currentDateRangePickerProps.styles.popper,
                        zIndex: zIndex.dropdown,
                    }}
                    additionalElement={currentDateRangePickerProps.rootRef}
                    {...currentDateRangePickerProps.attributes.popper}
                >
                    <Styled.MultiDateRangePickerWrapper
                        dropdownOpen={currentDateRangePickerProps.dropdownOpen}
                    >
                        {allowPrevious && (
                            <CRow
                                alignItems="center"
                                border="1px solid"
                                borderColor={Colors.grayLight}
                                gap={Spacing.XS}
                            >
                                <CSecondaryButton
                                    background={Colors.grayLightest}
                                    testId="toggle-compare-button"
                                    minWidth="150px"
                                    height="42px"
                                    borderRadius="0px"
                                    border="none"
                                    borderRight="1px solid"
                                    borderColor={Colors.grayLight}
                                    onClick={handleToggleCompare}
                                    fontSize={Typography.fontSizes.sm}
                                >
                                    {toggleCompare
                                        ? 'Hide Compare Dates'
                                        : 'Compare Dates'}
                                </CSecondaryButton>
                                <CRow
                                    py={Spacing.XXS}
                                    pr={Spacing.SM}
                                    alignItems="center"
                                >
                                    <CTextInput
                                        value={currentFormattedRange}
                                        onChange={() => 1}
                                        name="date1"
                                        testId="date1"
                                        hideError
                                        maxW="150px"
                                        onClick={() => setIsCurrent(true)}
                                        style={
                                            toggleCompare
                                                ? {
                                                      borderColor: isCurrent
                                                          ? theme.colors
                                                                .primary['100']
                                                          : theme.colors
                                                                .grayLight,
                                                  }
                                                : {}
                                        }
                                    />
                                    {toggleCompare && (
                                        <>
                                            <CSelectField
                                                isControlledInput
                                                placeholder="Compare To"
                                                name="compare-to"
                                                maxWidth="150px"
                                                testId="date-compare"
                                                onChange={
                                                    handleCompareInputChange
                                                }
                                                inputValue={selectedComparison}
                                                options={[
                                                    {
                                                        label: 'Prev Day',
                                                        value: 'day',
                                                    },
                                                    {
                                                        label: 'Prev 7 Days',
                                                        value: 'week',
                                                    },
                                                    {
                                                        label: 'Prev 30 Days',
                                                        value: 'month',
                                                    },
                                                    {
                                                        label: 'Custom',
                                                        value: 'custom',
                                                    }, // this button literally does nothing but product wants it
                                                ]}
                                                hideError
                                            />
                                            <CTextInput
                                                maxW="150px"
                                                value={previousFormattedRange}
                                                name="date2"
                                                testId="date2"
                                                hideError
                                                onChange={() => 1}
                                                onClick={() =>
                                                    setIsCurrent(false)
                                                }
                                                style={{
                                                    borderColor: !isCurrent
                                                        ? theme.colors.primary[
                                                              '100'
                                                          ]
                                                        : theme.colors
                                                              .grayLight,
                                                }}
                                            />
                                        </>
                                    )}
                                </CRow>
                            </CRow>
                        )}
                        {isCurrent ? (
                            <DateContent
                                dropdownOpen={
                                    currentDateRangePickerProps.dropdownOpen
                                }
                                selectedPresetDateRange={
                                    currentDateRangePickerProps.selectedPresetDateRange
                                }
                                datesMap={currentDateRangePickerProps.datesMap}
                                handleDatesRangeChange={
                                    currentDateRangePickerProps.handleDatesRangeChange
                                }
                                inputProps={inputProps}
                                name={name}
                                disallowFutureDates={disallowFutureDates}
                                startDateId={startDateId}
                                endDateId={endDateId}
                                v2
                                presetDateRanges={
                                    currentDateRangePickerProps.presetDateRanges
                                }
                                handlePresetRangeSelected={
                                    currentDateRangePickerProps.handlePresetRangeSelected
                                }
                                months={currentDateRangePickerProps.months}
                                hoveredDate={
                                    currentDateRangePickerProps.hoveredDate
                                }
                                error={currentDateRangePickerProps.error}
                                setHoveredDate={
                                    currentDateRangePickerProps.setHoveredDate
                                }
                                rangeIsValid={
                                    currentDateRangePickerProps.rangeIsValid
                                }
                                handleFirstMonthChange={
                                    currentDateRangePickerProps.handleFirstMonthChange
                                }
                                handleSecondMonthChange={
                                    currentDateRangePickerProps.handleSecondMonthChange
                                }
                                handleApply={applyDates}
                            />
                        ) : (
                            <DateContent
                                dropdownOpen={
                                    currentDateRangePickerProps.dropdownOpen
                                }
                                selectedPresetDateRange={
                                    previousDateRangePickerProps.selectedPresetDateRange
                                }
                                datesMap={previousDateRangePickerProps.datesMap}
                                handleDatesRangeChange={
                                    previousDateRangePickerProps.handleDatesRangeChange
                                }
                                inputProps={inputProps}
                                name={name}
                                disallowFutureDates={disallowFutureDates}
                                startDateId={startDateId}
                                endDateId={endDateId}
                                v2
                                presetDateRanges={
                                    previousDateRangePickerProps.presetDateRanges
                                }
                                handlePresetRangeSelected={
                                    previousDateRangePickerProps.handlePresetRangeSelected
                                }
                                months={previousDateRangePickerProps.months}
                                hoveredDate={
                                    previousDateRangePickerProps.hoveredDate
                                }
                                error={previousDateRangePickerProps.error}
                                setHoveredDate={
                                    previousDateRangePickerProps.setHoveredDate
                                }
                                rangeIsValid={
                                    previousDateRangePickerProps.rangeIsValid
                                }
                                handleFirstMonthChange={
                                    previousDateRangePickerProps.handleFirstMonthChange
                                }
                                handleSecondMonthChange={
                                    previousDateRangePickerProps.handleSecondMonthChange
                                }
                                handleApply={applyDates}
                            />
                        )}
                    </Styled.MultiDateRangePickerWrapper>
                </OnOutsideClick>
            </Portal>
        </Styled.DateRangePickerWrapper>
    );
};

/**
 * The `DateRangePickerField` is a strongly typed `Select`
 * that uses the `DateRangePicker` from `react-final-form`.
 *
 * It prevents us from having to re-type these type definitions
 * whenever we need to use a `DateRangePicker` with a `Field` component.
 */
export function DateRangePickerField(
    props: DateRangePickerFieldProps & { name: string }
): JSX.Element {
    const { name, v2 } = props;
    return (
        <Field<string, DateRangePickerFieldComponentProps, HTMLInputElement>
            {...props}
            name={name}
            component={DateRangePicker}
            v2={v2}
        />
    );
}

export default DateRangePicker;
