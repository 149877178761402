import { useFlags as useLaunchDarkly } from 'launchdarkly-react-client-sdk';

export type KoddiFlags = {
    /**   
               ___
            .-9 9 `\
          =(:(::)=  ;
            ||||     \
            ||||      `-.
           ,\|\|         `,
          /                \
         ;                  `'---.,
         |                         `\
         ;                     /     |
         \                    |      /
          )           \  __,.--\    /
       .-' \,..._\     \`   .-'  .-'
      `-=``      `:    |   /-/-/`
                   `.__/
    this is the typesafe walrus protector of our flags
     */

    campaignManagementToolEnabled: boolean;
    advertiserInsights: boolean;
    assetManagement: boolean;
    audienceReachEnabled: boolean;
    campaignHealthCheck: boolean;
    clientLevelSwapping: boolean;
    competitiveInsights: boolean;
    costPerMilli: boolean;
    customCampaignWizard: boolean;
    deleteCampaignOption: boolean;
    dimensionOverviewV2: boolean;
    elevio: boolean;
    enableHelpCenter: boolean;
    enableChatBot: boolean;
    experienceCreationEnabled: boolean;
    experiencesConfiguration: boolean;
    hasAcceleratedPacingOption: boolean;
    hasClientCreation: boolean;
    hasMultipleEntityFeedsEnabled: boolean;
    hasTutorialEnabled: boolean;
    inventoryInsights: boolean;
    isDesignHuddleEnabled: boolean;
    isInsertionOrderEnabled: boolean;
    isToaEnabled: boolean;
    isAccountsFilterDropdownEnabled: boolean;
    isAdvertiserSpendLimitEnabled: boolean;
    isAllAdvertisersSelectedPayloadEnabled: boolean;
    isAudiencesEnabled: boolean;
    isAutomatedEntityAssignmentEnabled: boolean;
    isBidRecommendationsEnabled: boolean;
    isBudgetRecommendationsEnabled: boolean;
    isCampaignDuplicationEnabled: boolean;
    isCampaignReactivationEnabled: boolean;
    isHistoryDrawerEnabled: boolean;
    isIntegrationsEnabled: boolean;
    isRecommendationsDashboardEnabled: boolean;
    isTargetingAutomationEnabled: boolean;
    isWireTransfersEnabled: boolean;
    localizeWidget: boolean;
    networkInsights: boolean;
    notifications: boolean;
    oneTimePayment: boolean;
    pauseScheduleCampaigns: boolean;
    publisherDashboardMvp: boolean;
    savedReports: boolean;
    scheduledReports: boolean;
    showPriorPeriodToggle: boolean;
    isLDDrawerEnabled: boolean;
    isEntityLevelBiddingEnabled: boolean;
    observabilityDashboard: boolean;
    isFeaturesPageEnabled: boolean;
    internalReports: boolean;
    isComplexTargetingEnabled: boolean;
};

export const useKoddiFlags = (): KoddiFlags => {
    return useLaunchDarkly<KoddiFlags>();
};
