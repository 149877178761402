/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-wrap-multilines */
import { Divider, Skeleton, SkeletonText } from '@chakra-ui/react';
import { useOnFirstRender } from 'app/react-ui/hooks/useOnFirstRender.hooks';
import React, { useState } from 'react';
import { CWhiteCard } from '../Cards';
import {
    BorderRadius,
    BorderWidth,
    Colors,
    FontSizes,
    Spacing,
} from '../ChakraTheme';
import { CChevronRightIcon } from '../Icons';
import {
    CColumn,
    CFormFieldContainer,
    CFormRow,
    CGrid,
    CRow,
} from '../Layouts';
import {
    CBarChartSkeleton,
    CCardSkeleton,
    CHeadingSkeleton,
    CIconSkeleton,
    CInputSkeleton,
    CLongCardWithHeadingSkeleton,
    CRadioCardSkeleton,
    CTextSkeleton,
} from './atoms';
import { CSkeletonBaseProps } from './Skeletons.type';

export const CSkeletonBase = ({
    isLoading,
    children,
    skeleton,
}: { skeleton: JSX.Element } & CSkeletonBaseProps): JSX.Element => {
    const [isFirstRender, setIsFirstRender] = useState(true);
    useOnFirstRender(() => setIsFirstRender(false));
    if (isLoading || isFirstRender) {
        return skeleton;
    }
    return <>{children}</>;
};

export const CGridRadioCardsSkeleton = ({
    numberOfCards = 4,
    numberOfColumns = 4,
    ...props
}: {
    numberOfCards?: number;
    numberOfColumns?: 2 | 3 | 4;
} & CSkeletonBaseProps): JSX.Element => {
    return (
        <CSkeletonBase
            {...props}
            skeleton={
                <CGrid numberOfColumns={numberOfColumns}>
                    {Array.from(Array(numberOfCards)).map((_, index) => (
                        <CRadioCardSkeleton key={`${index}-skeleton`} />
                    ))}
                </CGrid>
            }
        />
    );
};

export const COneColumnRadioCardsSkeleton = ({
    numberOfCards = 4,
    maxW,
    ...props
}: {
    numberOfCards?: number;
    maxW?: string | number;
} & CSkeletonBaseProps): JSX.Element => (
    <CSkeletonBase
        {...props}
        skeleton={
            <CColumn maxW={maxW}>
                {Array.from(Array(numberOfCards)).map((_, index) => (
                    <CRadioCardSkeleton key={`${index}-skeleton`} />
                ))}
            </CColumn>
        }
    />
);

export const CTableSkeleton = ({
    numberOfRows = 4,
    numberOfColumns = 4,
    ...props
}: {
    numberOfRows?: number;
    numberOfColumns?: number;
} & CSkeletonBaseProps): JSX.Element => (
    <CSkeletonBase
        {...props}
        skeleton={
            <CColumn>
                <CRow noBreak>
                    {Array.from(Array(numberOfColumns)).map((__, index) => (
                        <Skeleton
                            data-test="table-skeleton"
                            h={FontSizes.md}
                            w={`${100 / numberOfColumns}%`}
                            key={index}
                        />
                    ))}
                </CRow>
                <Divider />
                {Array.from(Array(numberOfRows)).map((_, index) => (
                    <CRow key={`${index}-skeleton`} noBreak>
                        {Array.from(Array(numberOfColumns)).map((__, i) => (
                            <Skeleton
                                h={FontSizes.md}
                                w={`${100 / numberOfColumns}%`}
                                key={i}
                            />
                        ))}
                    </CRow>
                ))}
            </CColumn>
        }
    />
);

export const CGridCardsSkeleton = ({
    numberOfCards = 4,
    numberOfColumns = 4,
    ...props
}: {
    numberOfCards?: number;
    numberOfColumns?: 2 | 3 | 4;
} & CSkeletonBaseProps): JSX.Element => (
    <CSkeletonBase
        {...props}
        skeleton={
            <CGrid numberOfColumns={numberOfColumns}>
                {Array.from(Array(numberOfCards)).map((_, index) => (
                    <CCardSkeleton key={`${index}}-skeleton`} />
                ))}
            </CGrid>
        }
    />
);

export const CFormSkeleton = ({
    ...props
}: CSkeletonBaseProps): JSX.Element => (
    <CSkeletonBase
        {...props}
        skeleton={
            <CFormFieldContainer>
                <CInputSkeleton />
                <CFormRow>
                    <CInputSkeleton />
                    <CInputSkeleton />
                </CFormRow>
                <CFormRow>
                    <CInputSkeleton />
                    <CInputSkeleton />
                </CFormRow>
                <CInputSkeleton />
                <CFormRow>
                    <CInputSkeleton />
                    <CInputSkeleton />
                </CFormRow>
                <Skeleton />
            </CFormFieldContainer>
        }
    />
);

export const CSectionSkeleton = ({
    ...props
}: CSkeletonBaseProps): JSX.Element => (
    <CSkeletonBase
        {...props}
        skeleton={
            <CColumn>
                <CHeadingSkeleton />
                <CTextSkeleton />
            </CColumn>
        }
    />
);

export const CNavItemSkeleton = ({
    showText,
    ...props
}: CSkeletonBaseProps & { showText: boolean }): JSX.Element => (
    <CSkeletonBase
        {...props}
        skeleton={
            <CRow alignItems="center" noBreak>
                <CIconSkeleton height="20px" width="20px" />
                {showText && <CHeadingSkeleton mb="0" />}
            </CRow>
        }
    />
);

export const CTwoTableSkeleton = ({
    ...props
}: CSkeletonBaseProps): JSX.Element => (
    <CSkeletonBase
        {...props}
        skeleton={
            <CFormRow>
                <CGrid>
                    <CLongCardWithHeadingSkeleton />
                    <CLongCardWithHeadingSkeleton />
                </CGrid>
            </CFormRow>
        }
    />
);

export const CExpandingSectionsSkeleton = ({
    numberOfSections,
    ...props
}: { numberOfSections: number } & CSkeletonBaseProps): JSX.Element => {
    const array = new Array(numberOfSections).fill(0).map((_, index) => index);
    return (
        <CSkeletonBase
            {...props}
            skeleton={
                <CColumn>
                    {array.map((section: number) => (
                        <CRow
                            key={section}
                            border={`${BorderWidth.Thin} solid`}
                            borderColor={Colors.skeleton}
                            borderRadius={BorderRadius.MD}
                            py={Spacing.XS}
                            px={Spacing.SM}
                            justifyContent="space-between"
                            alignItems="center"
                            height="40px"
                        >
                            <CHeadingSkeleton mb={0} />
                            <CChevronRightIcon
                                height="12px"
                                width="12px"
                                fill={Colors.skeleton}
                            />
                        </CRow>
                    ))}
                </CColumn>
            }
        />
    );
};

export const CBarChartsSkeleton = ({
    numberOfBars = 2,
    numberOfCharts = 1,
    chartHeight = 250,
    shouldWrap = false,
    ...props
}: {
    numberOfCharts?: 1 | 2 | 3 | 4;
    numberOfBars?: 2 | 4 | 8 | 16 | 24;
    chartHeight?: number;
    shouldWrap?: boolean;
} & CSkeletonBaseProps): JSX.Element => {
    return (
        <CSkeletonBase
            {...props}
            skeleton={
                <CRow flexWrap={shouldWrap ? 'wrap' : 'unset'}>
                    {Array.from(Array(numberOfCharts)).map((_, index) => (
                        <CColumn
                            key={`${index}-chart-column`}
                            width="275px"
                            flexGrow={1}
                        >
                            <CBarChartSkeleton
                                numberOfBars={numberOfBars}
                                chartHeight={chartHeight}
                            />
                        </CColumn>
                    ))}
                </CRow>
            }
        />
    );
};

export const CKPICardSkeleton = ({
    numberOfCards,
    ...props
}: { numberOfCards: number } & CSkeletonBaseProps): JSX.Element => {
    return (
        <CSkeletonBase
            {...props}
            skeleton={
                <>
                    {Array.from({ length: numberOfCards }, (_, index) => (
                        <CWhiteCard
                            key={index}
                            minWidth={250}
                            height={170}
                            w="100%"
                            padding={Spacing.SM}
                        >
                            <CColumn
                                gap={Spacing.SM}
                                justifyContent="space-between"
                                height="100%"
                            >
                                <SkeletonText noOfLines={1} />
                                <SkeletonText
                                    noOfLines={1}
                                    skeletonHeight="2rem"
                                />
                                <SkeletonText noOfLines={1} />
                            </CColumn>
                        </CWhiteCard>
                    ))}
                </>
            }
        />
    );
};

export const CCardColumnSeleton = ({
    numberOfCards,
    ...props
}: { numberOfCards: number } & CSkeletonBaseProps): JSX.Element => {
    return (
        <CSkeletonBase
            {...props}
            skeleton={
                <CColumn>
                    {Array.from({ length: numberOfCards }, (_, index) => (
                        <CCardSkeleton key={index} />
                    ))}
                </CColumn>
            }
        />
    );
};
